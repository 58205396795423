import React from "react";
import { Clickable } from "../";
import "./_styles.scss";

interface LibraryCardProps {
  to?: string;
  children?: React.ReactNode;
}

export default function LibraryCard({ to, children }: LibraryCardProps) {
  return (
    <Clickable
      className="h-app-library-card"
      to={to}
      isNavLink={to ? true : false}
    >
      {children}
    </Clickable>
  );
}
