import queryString from "query-string";

export default function getQueryParameter<T>(
  query: string,
  parameter: string,
  parser?: (...args: any[]) => T
): T | string {
  const val = queryString.parse(query)[parameter];

  if (parser) return parser(val);

  return val?.toString() ?? "";
}
