import React from "react";
import Clickable from "../clickable/Clickable";

export interface BreadcrumbProps {
  to?: string;
  text?: string;
}

export default function Breadcrumb({ to, text }: BreadcrumbProps) {
  if (!to) return <span>{text}</span>;

  return <Clickable to={to}>{text}</Clickable>;
}
