export default function generateColorFromString(
  val: string,
  saturation: number,
  lightness: number
): string {
  let hash = 0;

  for (let i = 0; i < val.length; i++)
    hash = val.charCodeAt(i) + ((hash << 5) - hash);

  const hue = hash % 360;

  return `hsl(${hue},${saturation}%,${lightness}%)`;
}
