import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import Breadcrumb, { BreadcrumbProps } from "./Breadcrumb";
import "./_styles.scss";

interface BreadcrumbTrailProps {
  breadcrumbs?: BreadcrumbProps[];
}

export default function BreadcrumbTrail({ breadcrumbs }: BreadcrumbTrailProps) {
  return (
    <div className="h-app-breadcrumb-trail">
      {breadcrumbs?.map((b, i) => (
        <React.Fragment key={`breadcrumb-${i}`}>
          <Breadcrumb to={b.to} text={b.text} />
          {i < breadcrumbs.length - 1 ? (
            <FontAwesomeIcon icon={faChevronRight} />
          ) : null}
        </React.Fragment>
      ))}
    </div>
  );
}
