import React from "react";
import { PageWrapper } from "../../components";

interface ErrorBaseProps {
  title: string;
  children: React.ReactNode;
}

export default function ErrorBase({ title, children }: ErrorBaseProps) {
  return (
    <PageWrapper className="error">
      <h1>{title}</h1>
      {children}
    </PageWrapper>
  );
}
